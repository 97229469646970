<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title>Summary Stats</v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card-text>
          <v-text-field
            :value="format_numbers(rev_summary.total_imp)"
            label="Impressions"
            outlined
            readonly
          ></v-text-field>
        </v-card-text>
      </v-col>
      <v-col>
        <v-card-text>
          <v-text-field
            :value="format_numbers(rev_summary.total_ecpm.toFixed(2))"
            label="eCPM (MYR)"
            outlined
            readonly
          ></v-text-field>
        </v-card-text>
      </v-col>
      <v-col>
        <v-card-text>
          <v-text-field
            :value="format_numbers(rev_summary.total_est.toFixed(2))"
            label="Ad Share Revenue (MYR)"
            outlined
            readonly
          ></v-text-field>
        </v-card-text>
      </v-col>
      <v-col v-if="typeof rev_summary.total_bonus !== 'boolean' && rev_summary.total_bonus !== false" >
        <v-card-text>
          <v-text-field
            :value="format_numbers(rev_summary.total_bonus.toFixed(2))"
            label="Bonus (MYR)"
            outlined
            readonly
          ></v-text-field>
        </v-card-text>
      </v-col>
      <v-col v-if="typeof rev_summary.total_est_after_bonus !== 'boolean' && rev_summary.total_est_after_bonus !== false">
        <v-card-text>
          <v-text-field
            :value="format_numbers(rev_summary.total_est_after_bonus.toFixed(2))"
            label="Total Revenue (MYR)"
            outlined
            readonly
          ></v-text-field>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-row class="mb-4">
              <v-col>
                <h4 class="ml-4 mb-0 mt-1">Ad Share Revenue Breakdown</h4>
              </v-col>
              <v-col>
                <v-btn
                  small
                  class="float-right mr-5"
                  @click="exportToCSV"
                  v-if="revenue_estimation.length > 0"
                >
                  DOWNLOAD CSV
                </v-btn>
              </v-col>
            </v-row>
            <v-card-text>
              <v-data-table
                :headers="headersRevenue"
                :items="revenue_estimation"
                :items-per-page="15"
                :loading="revenue_loading"
                :sort-by="['trackDate', 'source']"
                :sort-desc="[true, false]"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.trackDate="{ item }">
                  {{ showDate(item.trackDate) }}
                </template>
                <template v-slot:item.imp="{ item }">
                  {{ format_numbers(item.imp) || 0 }}
                </template>
                <template v-slot:item.eCPM="{ item }">
                  {{ format_numbers(item.eCPM.toFixed(2)) || 0 }}
                </template>
                <template v-slot:item.est_rev="{ item }">
                  {{ format_numbers(item.est_rev.toFixed(2)) || 0 }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import numeral from "numeral";

export default {
  name: "TrackMonetization",
  props: {
    publisher: {
      type: Object,
    },
    dateFrom: {
      type: String,
    },
    dateTo: {
      type: String,
    },
  },
  data() {
    return {
      headersRevenue: [
        { text: "Date", value: "trackDate" },
        { text: "Impressions", value: "imp" },
        { text: "eCPM (MYR)", value: "eCPM" },
        { text: "Ad Share Revenue (MYR)", value: "est_rev" },
      ],
      show: false,
      tab: null,
    };
  },
  filters: {
    upperCase(val) {
      return val.toUpperCase();
    },
    number(val) {
      if (val.toString().length > 6) {
        return numeral(val).format("0.0a");
      } else {
        return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      }
    },
  },
  computed: {
    revenue_estimation() {
      return this.$store.getters.getRevenue;
    },
    revenue_loading() {
      return this.$store.getters.getRevLoading;
    },
    rev_summary() {
      return this.$store.getters.getRevSummary;
    },
  },
  methods: {
    format_numbers(val) {
      return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    formatDate(date, separator = "/") {
      let dd = 0;
      let mm = 0;
      let yy = 0;

      dd = date.getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      mm = date.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      yy = date.getFullYear();
      return yy + separator + mm + separator + dd;
    },
    showDate(date) {
      date = date.split("-");
      return date[0] + "/" + date[1] + "/" + date[2];
    },
    exportToCSV() {
      let BOM = "\uFEFF";
      let dateFrom = this.formatDate(new Date(this.dateFrom));
      let dateTo = this.formatDate(new Date(this.dateTo));
      let csv = "";
      csv += "\nPublisher Name, Date From, Date To\n";
      csv += `"${this.publisher.name}", ${dateFrom}, ${dateTo} \n\n`;
      csv += "Summary, Impressions, eCPM (MYR), Estimated Revenue (MYR)\n";
      csv += `\t, ${this.rev_summary.total_imp}, ${this.rev_summary.total_ecpm}, ${this.rev_summary.total_est},\n\n`;
      csv += "Daily Breakdown, \t, \t,\n";
      csv += "Date, Impressions, eCPM (MYR), Estimated Revenue (MYR) \n";
      this.revenue_estimation.forEach((rev) => {
        csv += `"${rev.trackDate}", ${rev.imp}, ${rev.eCPM}, ${rev.est_rev}, \n`;
      });
      let reportName =
        this.publisher.name +
        "_MonetizationReport_" +
        this.formatDate(new Date(this.dateFrom), "") +
        "-" +
        this.formatDate(new Date(this.dateTo), "") +
        ".csv";
      let hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(BOM + csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = reportName;
      hiddenElement.click();
    },
  },
};
</script>

<style scoped>
</style>
