<template>
  <div>
    <v-row>
      <v-col>
        <Monetization/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Monetization from "./Monetization/ViewMonetization";
export default {
name: "MonetizationDashboard",
  components: {Monetization}
}
</script>

<style scoped>

</style>